import {CloseableModal, CloseableModalProps} from "../../../../../components/ui/modal/closeable-modal.component";
import {ConfirmButton, Container, CustomText} from "../../../../../styles/globalcomponents";
import {config} from "../../../../../config";

interface PlanExpirationModalProps extends Omit<CloseableModalProps, "children">{
}

const confirmPlanRenewalImg = `${config.urls.res}/platform/icons/PNG/confirm-plan-renewal-icon.png`

export function PlanExpirationModal({isOpen, closeModal}: PlanExpirationModalProps) {
    return (
        <>
          <CloseableModal isOpen={isOpen} closeModal={closeModal}>
            <Container
              flexDirection={"column"}
              alignItems={"center"}
              maxWidth={"45rem"}
              padding={"0 .5rem"}
            >
              <img
                src={confirmPlanRenewalImg}
                alt={"MasterTools icon plan renewal"}
                width={"170px"}
                height={"170px"}
              />
              <h2 className={"text-center"}>Tooler, tú cuenta se encuentra expirada!</h2>
              <Container flexDirection={"column"} margin={"0 0 1rem"}>
                <p>
                  Parece que el plan de tú cuenta no se encuentra con una suscripción vigente, para poder disfrutar de las herramientas de MasterTools reactiva la suscripción de tú plan.
                </p>
              </Container>
              <div>
                <ConfirmButton
                  onClick={() => closeModal()}
                >
                  ¡RENOVAR PLAN AHORA!
                </ConfirmButton>
                <CustomText
                  textAlign={"center"}
                  onClick={() => closeModal()}
                  cursor={"pointer"}
                  fontSize={"13px"}
                  textDecoration={"underline"}
                  margin={"1rem 0"}
                >
                  Cancelar
                </CustomText>
              </div>
            </Container>
          </CloseableModal>
        </>
      );
}
