export const AdditionalToolEmailSpecifications: string[] = [
    "Automatizaciones",
    "Campañas de Envío Masivo",
    'Constructor "Drag & Drop"',
    "Importar / Exportar Suscriptores"
]

export const addonsDataMock = [
  {
    id: 0,
    name: "0 Contactos",
    contacts: 0,
    price: 0,
    code: "",
  },
  {
    id: 1,
    name: "1.000 Contactos",
    contacts: 1000,
    price: 18,
    code: "P5S2D6B",
  },
  {
    id: 2,
    name: "2.500 Contactos",
    contacts: 2500,
    price: 45,
    code: "Y3N6F8E",
  },
  {
    id: 3,
    name: "5.000 Contactos",
    contacts: 5000,
    price: 75,
    code: "H3J0O5Z",
  },
  {
    id: 4,
    name: "7.500 Contactos",
    contacts: 7500,
    price: 105,
    code: "W2G8U5X",
  },
  {
    id: 5,
    name: "10.000 Contactos",
    contacts: 10000,
    price: 129,
    code: "E6A8E4J",
  },
  {
    id: 6,
    name: "+10.000 Contactos",
    contacts: 100001,
    price: 0,
    code: "",
  },
];
