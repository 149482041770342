import {instance, subsInstance} from "../axios/axiosConfig";
import axios from "axios";
import {config} from "../config";
import {
  GetSubscriptionStateResponse, GetSubscriptionStateResponseNew,
  PlanFrequency,
  SubscriptionInfo, UserSubscriptionStateNewestResponse,
  UserSubscriptionStateResponse,
} from "../interfaces/plans";
import {addDays, DatesLib} from "src/lib/datesLib";
import {DataUser} from "src/interfaces/redux";
import {LocalStorageKeys} from "../redux/localStorageKeys";

interface GetUserSubscriptionState {
  productId: number,
  newPlanInfo: SubscriptionInfo,
  newPlanPayFrequency: PlanFrequency
}


interface GetUserSubscriptionStateNewest {
    newPlan: SubscriptionInfo,
    periodicity: PlanFrequency
}

export const PRODUCT_IDS = {
  MasterTools: 395,
  EmailTool: 402
}

export class PlansService {
  datesLib = new DatesLib();

  checkPlanExpired(expirationDate: string) {
    return this.datesLib.isDateExpired(expirationDate);
  }

  async unsubscribe(form: any) {
    const response = await axios.post(
        `${config.axios.admUrl}/api/mastertoolsCancelations/insertCancelation`,
        form
    );
    return response.data;
  }

  async unsubscribeByPaytool(userData: any) {
    const response = await instance.post("/app/paytool/cancel/client/", {
      emailUser: userData.email,
      product: userData.product,
    });
    return response.data.data;
  }

  async detailSubscription() {
    const response = await instance.get("/app/suscription/detailSuscription/");
    return response.data.data;
  }

  async getUserSubscriptionState({productId, newPlanInfo, newPlanPayFrequency}: GetUserSubscriptionState) {
    const dataUser = this.getDataUser();
    const email = dataUser.email
    const newPlan = newPlanInfo
    const currentPlanName = dataUser.suscription.name
    const codePlans: any = {
      'Anual': newPlanInfo.codePlanAnualPaytool,
      'Mensual': newPlanInfo.codePlanPaytool
    }
    const planCode = codePlans[newPlanPayFrequency]
    if(!planCode) throw new Error("Plan frequency not found")

    const validationResponse = await subsInstance.get(`suscription/validation/${email}/${planCode}/${productId}`);
    if(validationResponse.data.codeResponse !== 200) throw new Error("Error validating subscription")

    const validationData = (validationResponse.data as UserSubscriptionStateResponse).data
    const nextPayment = validationData.nextPayment?.split("T", 1)

    const planData: GetSubscriptionStateResponse = {
      isApportionmentPrice: false,
      apportionmentPrice: undefined,
      typeChange: validationData.typeChange || "Upgrade",
      nextPayment: validationData.nextPayment ? String(nextPayment) : undefined,
      priceTotal: undefined,
      codePlanPaytool: planCode,
      valueToCharge: undefined,
    };

    if (!validationData.typeChange) throw new Error("typeChange is undefined, validation failed")

    if (validationData.typeChange === "Upgrade") {
      if (validationData.apportionmentPrice) {
        planData.isApportionmentPrice = true;
        planData.priceTotal = validationData.priceTotal;
        planData.apportionmentPrice = validationData.apportionmentPrice;
        return planData;
      }
      if (currentPlanName !== newPlan.name) {
        planData.priceTotal = validationData.priceTotal;
        return planData;
      }
      return planData;
    }

    if (validationData.typeChange === "Renewal") {
      const nextPaymentDate = String(addDays(new Date(), 30))
      planData.priceTotal = validationData.priceTotal;
      planData.nextPayment = nextPaymentDate;
      return planData;
    }

    if (!validationData.typeChange || validationData.typeChange === "Downgrade") {
      const nextPayment = validationData.nextPayment?.split("T", 1)
      planData.priceTotal = validationData.priceTotal;
      planData.nextPayment = String(nextPayment);
      planData.typeChange = "Downgrade";
      return planData;
    }
  }

  async getUserSubscriptionStateNewest({periodicity, newPlan: plan}: GetUserSubscriptionStateNewest) {
    const dataUser = this.getDataUser();
    const email = dataUser.email
    const newPlan = plan
    const codePlans: {[key in PlanFrequency]: string} = {
      'Mensual': newPlan.codePlanPaytool,
      'Trimestral': newPlan.codePlanTrimPayTool,
      'Semestral': newPlan.codePlanSemiAnualPaytool,
      'Anual': newPlan.codePlanAnualPaytool,
    }
    const codePlan = codePlans[periodicity]
    if(!codePlan) throw new Error("Plan frequency not found")

    const validationResponse = await instance.post(`/app/suscription/validateAction`, {
      email,
      planCode: codePlan,
    });

    if(!validationResponse.data.success) throw new Error("Error validating subscription")

    const validationData = (validationResponse.data as UserSubscriptionStateNewestResponse).data

    const planData: GetSubscriptionStateResponseNew = {
      typeChange: validationData.typeChange || "Upgrade",
      startDate: validationData.startDate.split("T")[0] as string,
      finalDate: validationData.finalDate.split("T")[0] as string,
      codePlanPaytool: codePlan,
      valueToCharge: undefined,
      isApportionmentPrice: validationData.isApportionment,
      priceTotal: validationData.amount,
      apportionmentPrice: validationData.amount,
      periodicity
    };

    return planData
  }

  async handlePayment(
      email: string,
      codePlanToChange: string,
      codeProduct: number,
      typeChange: string,
      valueToCharge: number
  ) {
    const response = await subsInstance.post("suscription/changeplan/", {
      email,
      codePlanToChange,
      codeProduct,
      typeChange,
      valueToCharge,
    });

    return response.data;
  }

  async validateIfDowngradeIsAlreadySchedule(email: string, planCode: string, productId: number):Promise<boolean> {
    const response = await subsInstance.get(`suscription/validate-schedule-downgrade/${email}/${planCode}/${String(productId)}`);
    return response.data.data;
  }

  private getDataUser() {
    const dataUser = JSON.parse(localStorage.getItem(LocalStorageKeys.userLoggedInfo) ?? '').dataUser as DataUser
    if (!dataUser) throw new Error("User not found")
    return dataUser;
  }
}
