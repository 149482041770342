import {Container, ContainerItem, CustomText, CustomTitle} from "../../../../../styles/globalcomponents";
import {SummaryToolLimits} from "./summaryToolLimits.component";
import {SpiralLoadingIcon} from "../../../../../components/loadingIcon/loadingIcon.component";
import {numberFormat} from "../../../../../lib/numberFormat";
import {CheckoutSummaryBodyStyled} from "./plansCheckoutSummary.styles";
import {useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../../../../hooks/useRedux";
import {PlansService, PRODUCT_IDS} from "../../../../../services/plansService";
import {ToastService} from "../../../../../services/toastService";
import {GetUserSubscriptionAddonState} from "../../../../../services/addonService";

const planService = new PlansService()
const toastService = new ToastService()
const DEBOUNCE_TIME_MS = 500

export function SummaryBody() {
    const selectedPlan = useAppSelector((state) => state.checkout.selectedPlan)
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const plans = useAppSelector((state) => state.auth.plans)
    const planInfo = plans.find((plan) => plan.suscriptionInfo.idSuscription === selectedPlan?.id)
    const additionalTools = useAppSelector((state) => state.checkout.additionalTools)
    const additionalToolEmail = additionalTools.find((tool) => tool.name === 'EmailTool')
    const [subscriptionPriceToPayNow, setSubscriptionPriceToPayNow] = useState(0)
    const [addonsPriceToPayNow, setAddonsPriceToPayNow] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const totalPrice = Number(subscriptionPriceToPayNow) + Number(addonsPriceToPayNow)
    const debounceRef = useRef<NodeJS.Timeout>()
    const showAdditionalToolSection = additionalToolEmail && additionalToolEmail.quantity !== 0
    const [typeChange, setTypeChange] = useState<string>()
    const [newPlanDates, setNewPlanDates] = useState<{startDate?: string, finalDate?: string}>()

    async function retrieveAddonValidationState() {
        try {
            setIsLoading(true)
            if(!additionalToolEmail || !additionalToolEmail.code) {
                setAddonsPriceToPayNow(0)
                return
            }

            const response = await GetUserSubscriptionAddonState({
                email: dataUser.email,
                planCode:additionalToolEmail.code,
            })

            if(response.isApportionmentPrice) {
                setAddonsPriceToPayNow(response.apportionmentPrice ?? 0)
            }else {
                setAddonsPriceToPayNow(response.priceTotal ?? 0)
            }
            setIsLoading(false)
        } catch (err){
            toastService.warning("Error validando el plan de addons")
        }
    }

    async function retrieveSubscriptionValidationState() {
        try {
            if (!planInfo || !selectedPlan || !additionalToolEmail) return toastService.warning("Error validando el plan")

            const response = await planService.getUserSubscriptionStateNewest({
                newPlan: planInfo.suscriptionInfo,
                periodicity: selectedPlan.frequency
            })

            if (!response) return toastService.warning("Error validando el plan")

            setNewPlanDates({
                startDate: response.startDate,
                finalDate: response.finalDate,
            })
            if(response.typeChange) setTypeChange(response.typeChange)
            if (response.isApportionmentPrice && response.apportionmentPrice) setSubscriptionPriceToPayNow(response.apportionmentPrice)
            if (!response.isApportionmentPrice && response.priceTotal) setSubscriptionPriceToPayNow(response.priceTotal)

        } catch (err){
            toastService.warning("Error validando el plan")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(debounceRef.current) {
            clearTimeout(debounceRef.current)
        }

        debounceRef.current = setTimeout(() => {
            retrieveSubscriptionValidationState()
            retrieveAddonValidationState()
        }, DEBOUNCE_TIME_MS)
    }, [planInfo?.suscriptionInfo.name, additionalToolEmail?.quantity])

    if(!planInfo || !selectedPlan) return <>Error calculando el plan 😭</>

    return (
        <>
            <CheckoutSummaryBodyStyled>
                <CustomTitle fontWeight={"700"} textAlign={"left"} margin={"0"} fontSize={"14px"} color={"black"}>
                    Incluye
                </CustomTitle>
                <SummaryToolLimits toolLimits={planInfo.limitsTool}/>
                <CustomTitle fontWeight={"700"} textAlign={"left"} margin={"0 0 1rem"} fontSize={"12px"}
                             color={"black"}>
                    {
                        typeChange === 'Downgrade' ? `Nuevo valor a pagar (Suscripción: ${planInfo.suscriptionInfo.name})` : 'Valor a pagar hoy (Prorrateo de días)'
                    }
                </CustomTitle>
                {
                    isLoading ?
                        <Container justifyContent={"center"}>
                            <SpiralLoadingIcon width={"3rem"}/>
                        </Container>
                        :
                        <Container display={"grid"} gridTemplateColumns={"1fr 45%"}>
                            <ContainerItem>
                                <CustomText fontSize={"11px"} color={"black"} textAlign={"left"} fontWeight={"400"}>
                                    {
                                        typeChange === 'Downgrade' ?
                                            `Este será el valor a pagar en tu próxima fecha de corte (${newPlanDates?.startDate})`
                                            :
                                            'Este es un valor prorrateado por los días de uso del nuevo plan.'
                                    }
                                </CustomText>
                            </ContainerItem>
                            <ContainerItem
                                alignItems={"flex-end"}>${numberFormat.format(subscriptionPriceToPayNow)} USD</ContainerItem>
                        </Container>
                }
                {
                    showAdditionalToolSection ?
                        <>
                            <hr/>
                            <CustomTitle fontWeight={"700"} textAlign={"left"} margin={"0"} fontSize={"14px"}
                                         color={"black"}>
                                Addons
                            </CustomTitle>
                            <Container display={"grid"} gridTemplateColumns={"1fr 30%"}>
                                <ContainerItem>{numberFormat.format(additionalToolEmail.quantity)} suscriptores</ContainerItem>
                                <ContainerItem
                                    alignItems={"flex-end"}>${numberFormat.format(additionalToolEmail.price)} USD</ContainerItem>
                            </Container>
                            <CustomTitle fontWeight={"700"} textAlign={"left"} margin={"1rem 0 1rem"} fontSize={"12px"} color={"black"}>
                                Valor a pagar hoy (H. Complementaria)
                            </CustomTitle>
                            {
                                isLoading ?
                                    <Container justifyContent={"center"}>
                                        <SpiralLoadingIcon width={"3rem"}/>
                                    </Container>
                                    :
                                    <Container display={"grid"} gridTemplateColumns={"1fr 30%"}>
                                        <ContainerItem>
                                            <CustomText fontSize={"11px"} color={"black"} textAlign={"left"}
                                                        fontWeight={"400"}>
                                                Este es un valor prorrateado por los días de uso de la herramienta
                                                complementaria.
                                            </CustomText>
                                        </ContainerItem>
                                        <ContainerItem alignItems={"flex-end"}>
                                            <CustomText fontSize={".9em"}>
                                                ${numberFormat.format(addonsPriceToPayNow)} USD
                                            </CustomText>
                                        </ContainerItem>
                                    </Container>
                            }
                        </>
                        : null
                }
                <hr/>
                <Container display={"grid"} gridTemplateColumns={"1fr 30%"}>
                    <ContainerItem>
                        <CustomText fontWeight={"400"} textAlign={"left"} margin={"0"} fontSize={"13px"} color={"gray"}>
                            Inicio de suscripción
                        </CustomText>
                    </ContainerItem>
                    <ContainerItem alignItems={"flex-end"}>
                        <CustomText fontSize={".9em"}>
                            {newPlanDates?.startDate}
                        </CustomText>
                    </ContainerItem>
                </Container>
                <hr/>
                <Container display={"grid"} gridTemplateColumns={"1fr 30%"}>
                    <ContainerItem>
                        <CustomText fontWeight={"400"} textAlign={"left"} margin={"0"} fontSize={"13px"} color={"gray"}>
                            Fin de suscripción
                        </CustomText>
                    </ContainerItem>
                    <ContainerItem alignItems={"flex-end"}>
                        <CustomText fontSize={".9em"}>
                            {newPlanDates?.finalDate}
                        </CustomText>
                    </ContainerItem>
                </Container>
                <hr/>
                <Container display={"grid"} gridTemplateColumns={"1fr 30%"}>
                    <ContainerItem>
                        <CustomText fontWeight={"400"} textAlign={"left"} margin={"0"} fontSize={"13px"} color={"gray"}>
                            Valor total de suscripciones
                        </CustomText>
                    </ContainerItem>
                    <ContainerItem alignItems={"flex-end"}>
                        <CustomText fontSize={".9em"}>
                            ${numberFormat.format(totalPrice)} USD
                        </CustomText>
                    </ContainerItem>
                </Container>
            </CheckoutSummaryBodyStyled>
        </>
    )
}
