import {Container, Title} from "../../../../../../styles/globalcomponents";
import {MembershipButton, PlansContainerStyled} from "./plans.styles";
import {Fragment, useCallback, useEffect, useState} from "react";
import {MembershipType} from "src/components/membership/membership-type.component";
import {Alert} from "../../../../../../components/alert/alert.component";
import CrispChatBox from "../../../../../../components/crispChatBox/crispChatBox";
import {useMobileScreen} from "../../../../../../hooks/useMobileScreen";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../../hooks/useRedux";
import {Plan, PlanFrequency} from "../../../../../../interfaces/plans";
import {PlansService} from "../../../../../../services/plansService";
import {ToastService} from "../../../../../../services/toastService";
import useUserData from "src/hooks/useUserData";
import {PlanCard} from "./planCard.component";
import {
    completeStep,
    moveToFollowingStep,
    setPlan,
    STEPS_INDEXES,
} from "../../../../../../redux/slices/checkout";
import {PLANS_HIERARCHY} from "../plans.data";
import {PlanSelectedDowngradeModal} from "./modals/planSelectedDowngrade.modal";
import {DatesLib} from "src/lib/datesLib";
import {PlanExpirationModal} from "../../../overview/modals/planExpiration.modal";

export type MembershipPeriodType = 'ANUAL' | 'SEMESTRAL' | 'MENSUAL' |  'TRIMESTRAL'


const toastService = new ToastService();
const plansService = new PlansService();
const dateManager = new DatesLib();

export const PlansOverview = () => {
    const plans = useAppSelector((state) => state.auth.plans);
    const dispatcher = useAppDispatch();
    const dataUser = useUserData();
    const mobileScreen = useMobileScreen();
    const expirationDate = dataUser.suscription.expired_time;
    const canceledPlan = dataUser.stateUser;
    const [organizedPlans, setOrganizedPlans] = useState<Plan[]>([]);
    const [membershipPeriodType, setMembershipPeriodType] = useState<PlanFrequency>('Anual');
    const [showExpiredPlanAlert, setShowExpiredPlanAlert] = useState(false);
    const [isPlanSelectedDowngradeModalOpen, setIsPlanSelectedDowngradeModalOpen] = useState<boolean>(false);
    let expiredPlan: boolean = false;

    function closePlanSelectedDowngrade() {
        setIsPlanSelectedDowngradeModalOpen(false);
    }

    const reorganizePlans = useCallback(() => {
        const userSubscription = dataUser.suscription.name;
        const plansInfoList = [...plans.filter(it => it.suscriptionInfo.name !== 'Lite')];

        if (!membershipPeriodType && userSubscription === "Lite") {
            const litePlanIndex = plansInfoList.findIndex((plan: Plan) => plan.suscriptionInfo.name === "Lite");
            const litePlan = plansInfoList.splice(litePlanIndex, 1);
            return setOrganizedPlans([...litePlan, ...plansInfoList]);
        }

        if (userSubscription === "Starter" && membershipPeriodType === "Mensual") {
            const starterPlanIndex = plansInfoList.findIndex((plan: Plan) => plan.suscriptionInfo.name === "Starter");
            const starterPlan = plansInfoList.splice(starterPlanIndex, 1);
            const withoutBasic = plansInfoList.filter(it => it.suscriptionInfo.name !== "Basic");

            return setOrganizedPlans([...starterPlan, ...withoutBasic]);
        }

        setOrganizedPlans([...plansInfoList.filter(it => it.suscriptionInfo.name !== "Starter")]);
    }, [dataUser.suscription.name, membershipPeriodType, plans])

    function switchMembership(membershipPeriodType: PlanFrequency) {
        setMembershipPeriodType(membershipPeriodType);
    }

    function getMembershipName() {
        return membershipPeriodType;
    }

    function canPurchasePlan(plan: Plan) {
        return (
            !plansService.checkPlanExpired(expirationDate) &&
            plan.suscriptionInfo.name === dataUser.suscription.name &&
            getMembershipName() === dataUser.suscription.typeSuscription &&
            !expiredPlan
        );
    }

    function handleSelectPlan(plan: Plan) {
        const isALowerPlanThanCurrent = PLANS_HIERARCHY[plan.suscriptionInfo.name] < PLANS_HIERARCHY[dataUser.suscription.name];
        const planIsExpired = dateManager.isDateExpired(dataUser.suscription.expired_time)


        if (planIsExpired && isALowerPlanThanCurrent) {
            return setIsPlanSelectedDowngradeModalOpen(true);
        }

        if (isALowerPlanThanCurrent && dataUser.suscription?.isDowngradeScheduled) {
            return toastService.warning(
                "Ya está programada una disminución del plan"
            );
        }
        dispatcher(
            setPlan({
                id: plan.suscriptionInfo.idSuscription,
                frequency: getMembershipName(),
            })
        );
        dispatcher(completeStep({stepId: STEPS_INDEXES.selectPlan}));
        dispatcher(moveToFollowingStep({stepId: STEPS_INDEXES.planDescription}));
    }

    useEffect(() => {
        if (expirationDate || canceledPlan) {
            const isDateExpired = plansService.checkPlanExpired(expirationDate);
            const isCanceledPlan = canceledPlan === 2;

            expiredPlan = isDateExpired || isCanceledPlan;
            setShowExpiredPlanAlert(expiredPlan);
        }
    }, []);

    useEffect(() => {
        reorganizePlans();
    }, [plans, membershipPeriodType, reorganizePlans]);


    return (
        <>
            <Title
                color="black"
                fontSize="15px"
                margin="0 0 .5rem"
                responsive="text-align: center;"
                textAlign="center"
            >
                Planes y suscripciones
            </Title>
            <MembershipType>
                <MembershipButton
                    onClick={() => switchMembership("Mensual")}
                    active={membershipPeriodType === 'Mensual'}
                >
                    Mensual
                </MembershipButton>
                <MembershipButton
                    onClick={() => switchMembership("Trimestral")}
                    active={membershipPeriodType === 'Trimestral'}
                >
                    Trimestral

                </MembershipButton>
                <MembershipButton
                    onClick={() => switchMembership("Semestral")}
                    active={membershipPeriodType === 'Semestral'}
                    promotion={"10% off"}
                >
                    Semestral
                </MembershipButton>
                <MembershipButton
                    onClick={() => switchMembership("Anual")}
                    active={membershipPeriodType === 'Anual'}
                    promotion={"20% off"}
                >
                    Anual
                </MembershipButton>
            </MembershipType>

            <Container flexDirection="column" margin="0">
                <Container flexDirection="column">
                    <PlansContainerStyled margin="2rem auto 0">
                        {organizedPlans.map((plan, index) => {
                            const SUBSCRIPTION_ACTIVE = 1;
                            const canSeePlanList =
                                plan.suscriptionInfo.name !== "Pioneros" &&
                                plan.suscriptionInfo.name !== "Trial" &&
                                plan.suscriptionInfo.state === SUBSCRIPTION_ACTIVE;
                            if (canSeePlanList)
                                return (
                                    <PlanCard
                                        key={`plan-cards-${index}`}
                                        plan={plan}
                                        onUserSubscription={handleSelectPlan}
                                        active={canPurchasePlan(plan)}
                                        membershipPeriodType={membershipPeriodType}
                                    />
                                );
                            return <Fragment key={`plan-cards-${index}`}></Fragment>;
                        })}
                    </PlansContainerStyled>
                </Container>
            </Container>
            {!mobileScreen ? <CrispChatBox/> : null}
            <PlanExpirationModal isOpen={showExpiredPlanAlert} closeModal={() => setShowExpiredPlanAlert(false)}  />
            <PlanSelectedDowngradeModal
                isOpen={isPlanSelectedDowngradeModalOpen}
                closeModal={closePlanSelectedDowngrade}
            />
        </>
    );
};
