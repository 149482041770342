import {
    PlanCardStyled,
    PlanDescription,
    PlanHeader,
    PlanPrice, PlanPriceNumber,
    PlanTitle,
    PlanTool,
    PlanToolsContainer
} from "./plans.styles";
import {config} from "../../../../../../config";
import {Fragment, useState} from "react";
import {Plan, PlanFrequency} from "../../../../../../interfaces/plans";
import {PLANS_BANNERS, TOOLS_ICONS} from "../plans.data";
import {PlanDescriptionModal} from "./modals/planDescription.modal";
import {ConfirmButton} from "../../../../../../styles/globalcomponents";

interface PlanCardProps {
    active?: boolean
    plan: Plan
    membershipPeriodType: PlanFrequency
    onUserSubscription: (plan: Plan) => void
}

export function PlanCard({active, plan, membershipPeriodType, onUserSubscription}: PlanCardProps) {
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false)
    const subscriptionName = plan.suscriptionInfo.name
    const haveLitePlan = subscriptionName === "Lite"
    const haveBasicOrLitePlan = haveLitePlan || subscriptionName === "Basic";
    const backgroundImage = PLANS_BANNERS[subscriptionName.toLowerCase()] ?? PLANS_BANNERS[0]
    const price = membershipPeriodType === 'Anual' ? plan.suscriptionInfo.anualPrice :
        membershipPeriodType === 'Semestral' ? plan.suscriptionInfo.semiAnualPrice :
        membershipPeriodType === 'Trimestral' ? plan.suscriptionInfo.trimesterPrice :
        membershipPeriodType === 'Mensual' ? plan.suscriptionInfo.monthPrice :
        undefined
    const pricingPeriod = membershipPeriodType === 'Anual' ? "USD / año" :
        membershipPeriodType === 'Semestral' ? "USD / semestre" :
        membershipPeriodType === 'Trimestral' ? "USD / trimestre" :
        membershipPeriodType === 'Mensual' ? "USD / mes" :
        ""

    function closeDescriptionModal() {
        setIsDescriptionModalOpen(false)
    }

    return (
        <>
            <PlanCardStyled
                active={active}
            >
                <PlanHeader backgroundImage={backgroundImage}>
                    <PlanTitle>
                        Plan {subscriptionName}
                    </PlanTitle>
                </PlanHeader>
                <PlanDescription>
                    <PlanToolsContainer>
                        {TOOLS_ICONS.map((toolIcon, index) => {
                            const isEmailToolImg = toolIcon === `${config.urls.res}/platform/icons/PNG/Icono-emailtool.png`
                            const showEmailToolDependOnPlan = haveBasicOrLitePlan && isEmailToolImg
                            if (showEmailToolDependOnPlan) {
                                return <Fragment key={`tools-${index}`}></Fragment>;
                            } else {
                                return <PlanTool src={toolIcon} key={`tools-${index}`}></PlanTool>;
                            }
                        })}
                    </PlanToolsContainer>
                    <PlanPrice>
                        <PlanPriceNumber>
                            ${price} {pricingPeriod}
                        </PlanPriceNumber>
                    </PlanPrice>
                    <ConfirmButton
                        disabled={active}
                        onClick={() => onUserSubscription(plan)}
                    >
                        {active ? "Plan actual" : "Seleccionar plan"}
                    </ConfirmButton>
                    <button className={"link"} onClick={() => setIsDescriptionModalOpen(true)}>
                        Ver descripción del plan
                    </button>
                </PlanDescription>
            </PlanCardStyled>
            <PlanDescriptionModal planName={subscriptionName} periodicity={membershipPeriodType} isOpen={isDescriptionModalOpen}
                                  closeModal={closeDescriptionModal}/>
        </>
    )
}
