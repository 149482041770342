import {instance, subsInstance} from "../axios/axiosConfig";
import {addonsDataMock} from "../pages/profile/subscription/plan/tabs/planDescriptionTab/additionalTool/addons.data";
import {
  EmailToolAccountResponse,
  GetSubscriptionStateResponseNew,
  UserSubscriptionStateNewestResponse
} from "../interfaces/plans";

export async function ExecuteActivationSubProductPayment(
    {email, codePlan, idProduct, amount}: { email: string, codePlan: string, idProduct: number, amount: number }
) {
  const response = await subsInstance.post(`suscription/subproduct/approved`, {
    doc_client: "",
    email,
    code_plan: codePlan,
    id_product: idProduct,
    transaction_info: {
      amount,
      currency_code: "USD",
    },
  });

  return response.data;
}

export async function GetUserEmailToolContactsConsumed(idUser: string) {
  const response = await instance.get(`/app/addons/account-status/${idUser}`,)
  return response.data.data as EmailToolAccountResponse
}

export async function GetUserSubscriptionAddonState(
    {email, planCode, productId}: { email: string, planCode: string, productId?: number}
) {
  const validationResponse = await instance.post(`/app/suscription/validateAction`, {
    email,
    planCode,
  });

  if(!validationResponse.data.success) throw new Error("Error validating addons subscription")

  const validationData = (validationResponse.data as UserSubscriptionStateNewestResponse).data

  const planData: GetSubscriptionStateResponseNew = {
    isActivation: !validationData.isChangePlan,
    isApportionmentPrice: Boolean(validationData.amount),
    apportionmentPrice: validationData.amount,
    typeChange: validationData.typeChange || "Upgrade",
    startDate: validationData.startDate.split("T")[0] as string,
    finalDate: validationData.finalDate.split("T")[0] as string,
    priceTotal: validationData.amount,
    codePlanPaytool: planCode,
    valueToCharge: undefined,
    periodicity: "Mensual"
  };

  if (validationData.typeChange === "Upgrade") {
    planData.isChangePlan = validationData.isChangePlan
    return planData;
  }

  if(validationData.typeChange === 'Renewal') {
    return planData
  }

  return planData
}

export const GetCurrentPlan = (userContacts: string | number) => {
  const addData = addonsDataMock.filter(
    (addon) => addon.contacts === (typeof userContacts === 'string' ? parseInt(userContacts) : userContacts)
  );

  return addData[0];
};
