import {TermsStyled} from "../../../../unsubscribe/unsubscribe.styles";
import {ConfirmButton, Container, CustomText} from "../../../../../../../styles/globalcomponents";
import {ToastService} from "../../../../../../../services/toastService";
import {useAppSelector} from "../../../../../../../hooks/useRedux";
import {useEffect, useRef, useState} from "react";
import {config} from "../../../../../../../config";
import {GetSubscriptionStateResponse, GetSubscriptionStateResponseNew} from "../../../../../../../interfaces/plans";
import {PlansService, PRODUCT_IDS} from "../../../../../../../services/plansService";
import {
    ExecuteActivationSubProductPayment,
    GetUserSubscriptionAddonState
} from "../../../../../../../services/addonService";
import {ConfirmAddonTabParagraphs} from "./confirmTabParagraphs.component";
import {SpiralLoadingIcon} from "../../../../../../../components/loadingIcon/loadingIcon.component";
import {addonsDataMock} from "../../../tabs/planDescriptionTab/additionalTool/addons.data";

interface ConfirmAddonTabProps {
    closePurchaseModal: (force?: boolean) => void
    openSuccessModal: () => void
}

const confirmAddonImg = `${config.urls.res}/platform/icons/PNG/confirm-plan-renewal-icon.png`
const toastService = new ToastService()
const ACTIVE_STATE = 1
export function ConfirmAddonTab({closePurchaseModal, openSuccessModal}: ConfirmAddonTabProps) {
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const additionalTools = useAppSelector((state) => state.checkout.additionalTools)
    const additionalToolEmail = additionalTools.find((tool) => tool.name === 'EmailTool')
    const [validationData, setValidationData] = useState<GetSubscriptionStateResponseNew | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
    const [paymentApproved, setPaymentApproved] = useState<boolean>(false)
    const userBelongsToPayTool = dataUser.existPaytoolLogic === ACTIVE_STATE
    const belongsToHotmart = !userBelongsToPayTool
    const addonPrice = validationData?.apportionmentPrice ?? validationData?.priceTotal ?? 0
    const currentAddonSubscription = dataUser.addonsInfo.emailContacts.find((element) => element.state === ACTIVE_STATE)
    const isFirstTimePurchase = currentAddonSubscription === undefined
    const currentAddonInfo = addonsDataMock.find((element) => element.contacts === currentAddonSubscription?.quantity)
    const newAddonPlan = {
        name: additionalToolEmail?.addonPlanName ?? '',
        monthPrice: additionalToolEmail?.price ?? 0
    }

    async function validateSubscription() {
        try {
            setIsLoading(true)
            if(!additionalToolEmail || additionalToolEmail.quantity === 0) return
            if(!additionalToolEmail || !additionalToolEmail.code) throw new Error("Error seleccionando plan")

            const response = await GetUserSubscriptionAddonState({
                email: dataUser.email,
                planCode:additionalToolEmail.code,
            })
            setValidationData(response)
            setIsLoading(false)
        } catch (err){
            toastService.warning("Error validando el plan")
        }
    }

    async function handleChangePlan() {
        if (belongsToHotmart) return window.open("https://mastertools.com/chat", "_blank");

        try {
            setIsSubmitting(true)
            if(!validationData) throw new Error("Additional tool validation state fails")

            if (validationData?.isActivation && validationData.typeChange !== 'Renewal') {
                await ExecuteActivationSubProductPayment({
                    email: dataUser.email,
                    codePlan: validationData?.codePlanPaytool,
                    idProduct: PRODUCT_IDS.EmailTool,
                    amount: validationData.apportionmentPrice || validationData.priceTotal || 0
                });
            } else {
                // Execute change plan payment
                const plansService = new PlansService();
                await plansService.handlePayment(
                    dataUser.email,
                    validationData.codePlanPaytool,
                    PRODUCT_IDS.EmailTool,
                    validationData.typeChange,
                    validationData.apportionmentPrice || validationData.priceTotal || 0
                );
            }
            setPaymentApproved(true)
            setIsSubmitting(false)
            toastService.success("¡Compra exitosa!");
        } catch (error) {
            toastService.error("¡Tu pago ha sido rechazado!");
        }
    }

    function handleShowSuccessfulMessage() {
        openSuccessModal()
        closePurchaseModal(true)
        toastService.success("¡Se terminó tu compra! Revisa tu correo electrónico en unos momentos")
    }

    useEffect(() => {
        validateSubscription()
    }, [openSuccessModal])

    return (
        <Container flexDirection={"column"} alignItems={"center"} maxWidth={"45rem"} padding={"0 .5rem"}>
            <img src={confirmAddonImg} alt={"MasterTools icon plan confirmation"} width={"170px"} height={"170px"} />
            <h2 className={"text-center m-0"}>
                {getTitleCopy(validationData?.typeChange ?? '')}
            </h2>
            {
                !isLoading && validationData && additionalToolEmail ?
                    <ConfirmAddonTabParagraphs isFirstTimePurchase={isFirstTimePurchase} changeType={validationData.typeChange ?? ''} currentAddonPlanName={currentAddonInfo?.name ?? ''} newAddonPlan={newAddonPlan} price={addonPrice}  startDate={validationData.startDate ?? ""} finalDate={validationData.finalDate ?? ''} />
                :
                    <SpiralLoadingIcon width={"3rem"} />
            }
            <TermsStyled>
                <input type="checkbox" onChange={() => setTermsAccepted(prev => !prev)}/>
                <small>
                    <i>Entiendo que al dar click en el botón “¡QUIERO MI NUEVO PLAN!” estoy realizando un pago proporcional a los días de uso de la suite de herramientas en MasterTools y autorizo que sea cobrado de la tarjeta de crédito registrada en mi cuenta. También estoy aceptando los <a href="https://mastertools.com/politica-servicio"  rel="noreferrer" target="_blank">términos de compra</a> y servicio de MasterTools.</i>
                </small>
            </TermsStyled>
            {
                isSubmitting ?
                    <SpiralLoadingIcon width={"3rem"} />
                :
                    !paymentApproved ?
                        <div>
                            <ConfirmButton onClick={handleChangePlan} disabled={!termsAccepted || isSubmitting}>
                                ¡QUIERO MI NUEVO PLAN!
                            </ConfirmButton>
                            <CustomText textAlign={"center"} onClick={() => closePurchaseModal()} cursor={"pointer"} fontSize={"13px"} textDecoration={"underline"} margin={"1rem 0"}>
                                Cancelar
                            </CustomText>
                        </div>
                    :
                        <div>
                            <ConfirmButton onClick={handleShowSuccessfulMessage}>
                                FINALIZAR
                            </ConfirmButton>
                        </div>
            }
        </Container>
    )
}

function getTitleCopy(typeChange: string) {
    if(typeChange === 'Upgrade') return "¡Uff! ¡Qué buena elección!"
    if(typeChange === 'Downgrade') return "¿Listo para utilizar MasterTools a un menor costo?"
    if(typeChange === 'Renewal') return "¡Tus contactos comenzaban a extrañarte!"
    return "¡Hola, Tooler!"
}
