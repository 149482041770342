import {SubscriptionInfo} from "../../../../../../../interfaces/plans";
import {getFormattedNumber} from "../../../../../../../lib/numberFormat";
import {useAppSelector} from "../../../../../../../hooks/useRedux";

interface ConfirmAddonTabParagraphsProps {
    changeType: string,
    currentAddonPlanName: string,
    newAddonPlan: {
        name: string
        monthPrice: number
    },
    price: number,
    isFirstTimePurchase: boolean,
    finalDate: string,
    startDate: string
}

export function ConfirmAddonTabParagraphs({changeType, newAddonPlan, currentAddonPlanName, price, isFirstTimePurchase, startDate, finalDate}: ConfirmAddonTabParagraphsProps) {
    const userSubscription = useAppSelector((state) => state.auth.dataUser.suscription)
    const formattedPrice = getFormattedNumber(price)

    if(isFirstTimePurchase) {
        return (
            <div>
                <p>
                    Tu comunidad está creciendo y para recibirla agregaremos a tu cuenta un nuevo plan de contactos a tu herramienta de Email Marketing.
                </p>
                <p>
                    (Podrás utilizar la herramienta tan pronto como toques el botón!)
                </p>
                <p>
                    Para eso, cobraremos <strong>${formattedPrice} USD</strong>  en tu tarjeta por el uso de tu nuevo plan de contactos.
                </p>
                <p>
                    Y a partir del próximo cobro de tu plan <strong>"{newAddonPlan.name}"</strong> pagarías: <strong>${getFormattedNumber(newAddonPlan.monthPrice)} USD</strong> adicional al valor de tu plan <strong>{userSubscription.name}</strong> de MasterTools.
                </p>
                <p className={"text-center"}>
                    <strong>Solo toca el botón para confirmar tu nueva elección!</strong>
                </p>
            </div>
        )
    }

    if(changeType === 'Upgrade') {
        return (
            <div>
                <p>
                    Lo nuestro continúa, pero mejor que antes <strong>;)</strong> Adiós a tu limitada lista del plan <strong>"{currentAddonPlanName}"</strong> y hola a los nuevos contactos de tu plan <strong>"{newAddonPlan.name}"</strong>.
                </p>
                <p>
                    Cobraremos <strong>${formattedPrice} USD</strong> a tu tarjeta por el uso de tu nuevo plan durante los días antes del próximo cobro: <strong>{finalDate}</strong>.
                </p>
                <p>
                    Y a partir del próximo cobro pagarías <strong>${getFormattedNumber(newAddonPlan.monthPrice)} USD</strong> por tu plan <strong>"{newAddonPlan.name}"</strong> adicional al valor de tu plan <strong>{userSubscription.name}</strong> de MasterTools.
                </p>
                <p className={"text-center"}>
                    <strong>Solo toca el botón para confirmar tu nueva elección!</strong>
                </p>
            </div>
        )
    }
    if(changeType === 'Downgrade') {
        return (
            <div>
                <p>
                    Nada mejor que acceder a oportunidades únicas! Sólo por esta ocasión podrás disfrutar de las funcionalidades del plan <strong>{newAddonPlan.name}</strong> por un valor de: <strong>${formattedPrice} USD</strong> que será cargado en tu próxima fecha de cobro <strong>{startDate}</strong>. Así podrás utilizar un plan de MasterTools a un menor costo del valor actual.
                </p>
                <p>
                    *No cobraremos nada en este momento ;)
                </p>
                <p className={"text-center"}>
                    <strong>¡Toca el botón para empezar a usar MasterTools al menor costo!</strong>
                </p>
            </div>
        )
    }
    if(changeType === 'Renewal') {
        return (
            <div>
                <p>
                    Para seguir construyendo la relación con tu lista, toca el botón y activaremos inmediatamente tu suscripción de <strong>"{newAddonPlan.name}"</strong>, cobraremos <strong>${formattedPrice} USD</strong>. a tu tarjeta y no volveremos a cobrar hasta <strong>{finalDate}</strong>
                </p>
                <p>
                    Y a partir del próximo cobro de tu plan <strong>"{newAddonPlan.name}"</strong> pagarías: <strong>${getFormattedNumber(newAddonPlan.monthPrice)} USD</strong> adicional al valor de tu plan <strong>{userSubscription.name}</strong> de MasterTools.
                </p>
                <p className={"text-center"}>
                    <strong>Solo toca el botón para confirmar tu nueva elección!</strong>
                </p>
            </div>
        )
    }

    return (
        <p>No se encontró información</p>
    )
}
